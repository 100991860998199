<template>
  <div class="payment">
    <heads></heads>
    <div
      class="con_wrap"
      :style="{
        backgroundImage: 'url(' + require('@/assets/' + backgroud) + ')',
      }"
    >
      <div class="pay-box">
        <el-row>
          <el-col :span="24" class="pay_tip">
            <h2>支付中心</h2>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24" class="pay_out_trade_no">
            <p>订单编号：{{ payData.order.out_trade_no }}</p>
          </el-col>
        </el-row>

        <el-row class="pay-list">
          <el-col :span="12" class="pay_detail">
            <span>
              <h3>您正在支付 {{ payData.order.detail }}</h3>
            </span>
            <span class="order_detail_attach">
              有效期 :
              <span class="pice_yinian_s"> 自购买之日起一年有效 </span>
            </span>
          </el-col>
          <el-col :span="12" class="pay_price">
            <span class="sfje_txt">
              实付金额：
              <span class="yang">￥</span>
              <span class="pice_coin">{{ payData.order.total_fee }}</span>
            </span>
          </el-col>
        </el-row>

        <el-row class="pay_tip pay_way">
          <el-col :span="24">
            <h2>支付方式</h2>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="6">
            <div class="methopd_box kk this_position_pos mg_left_mg_left">
              <img class="pice_imgg" src="../assets/images/wx.png" alt="" />
            </div>
          </el-col>

          <el-col :span="18" class="pay_explain">
            <p>说明:</p>
            <p>
              您购买的商品为虚拟商品，故在购买后不支持退单、转让、退换等服务。
            </p>
            <p>完成支付后在个人中心中查看已购课程。</p>
          </el-col>
        </el-row>

        <el-row class="pay_button">
          <el-col :span="24">
            <button
              class="layui-btn layui-btn-lg to-pay"
              style="background-color: #c30d23"
              @click="toPay()"
            >
              立即支付
            </button>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="show-wxpay-scan">
      <div class="show-image">
        <img :src="payData.pay_url" alt="扫码支付" />
      </div>
      <div class="show-tishi">
        <img src="../assets/images/tishi.png" alt="" />
      </div>
    </div>

    <foot></foot>
  </div>
</template>

<script>
import "@css/ico.css";
import "@css/foot.css";
import heads from "@components/head.vue";
import foot from "@components/foot.vue";
import { toPay, payStatus } from "@api/user";
export default {
  components: {
    heads,
    foot,
  },
  data() {
    return {
      queryWhere: {},
      payData: {
        pay_url: "",
        order: {},
      },
    };
  },
  created: function () {
    this.queryWhere = this.$route.query;
    this.backgroud = this.$route.meta.background;
    document.title = this.$route.meta.title;
  },
  mounted: function () {
    let that = this;
    toPay(that.queryWhere).then(function (res) {
      that.$set(that.payData, "pay_url", res.data.pay_url);
      that.$set(that.payData, "order", res.data.order);
    });
  },
  methods: {
    toPay() {
      let that = this;
      layui.use(["layer", "jquery"], function () {
        var layer = layui.layer,
          $ = layui.jquery,
          payInterval = "";
        layer.open({
          title: false,
          type: 1,
          closeBtn: 0,
          anim: 2,
          area: ["260px", "320px"],
          shadeClose: true,
          content: $(".show-wxpay-scan"),
          success: function (layero, index) {
            payInterval = window.setInterval(function () {
              payStatus(that.queryWhere).then((res) => {
                if (res.data.trade_state == "success") {
                  window.clearInterval(payInterval);
                  layer.msg("支付成功，正在跳转", {
                    icon: 1,
                    time: 1000,
                    end: function () {
                      window.location.href = "/user";
                    },
                  });
                }
              });
            }, 6000);
          },
          end: function () {
            window.clearInterval(payInterval);
            $(".show-wxpay-scan").hide();
          },
        });
      });
    },
  },
};
</script>

<style>
.payment > .con_wrap {
  width: 100%;
  height: 988px;
  background-size: cover;
  background-position: center 0;
}

.pay-box {
  position: absolute;
  width: 900px;
  transform: translateX(-50%);
  left: 50%;
  top: 360px;
}

.pay-box .pay_tip h2 {
  position: relative;
  padding: 15px 0;
  font-weight: 500;
  color: #666666;
  font-family: HYQuanTangShiJ;
  font-size: 28px !important;
  margin-top: 0px !important;
}

.pay-box .pay_out_trade_no p {
  position: relative;
  padding: 15px 0;
  color: #666666;
  border-bottom: 1px solid #00be06;
}

.pay-list {
  background-color: #fff;
  padding: 20px;
  margin-top: 15px;
  border-radius: 5px;
}

.pay-list .pay_detail {
  position: inherit;
  text-align: center;
}

.order_detail_attach {
  position: relative;
  height: auto;
  display: block;
  font-size: 14px;
  color: #818181;
  margin-top: 15px;
}

.pice_yinian_s {
  color: #333333;
  font-size: 14px;
  margin-left: 22px;
}

.pay-list .pay_price {
  text-align: right;

  line-height: 50px;
  padding-right: 20px;
}

.sfje_txt {
  height: auto;
  display: block;
  color: #333333;
  font-size: 14px;
  position: relative;
  text-align: right;
}

.yang {
  color: #fb704f;
  font-size: 16px;
}

.pice_coin {
  color: #fb704f;
  font-size: 26px;
}

.pay_tip.pay_way {
  border-bottom: 1px solid #00be06;
  margin-top: 40px;
}

.pay_button {
  text-align: right;
  margin-top: 30px;
}

.methopd_box {
  width: 152px;
  height: 52px;
  border: 0 solid red;
  float: left;
  margin-top: 26px;
  margin-right: 48px;
  cursor: pointer;
}

.this_position_pos {
  background-position: center -52px !important;
}
.kk {
  background: url(../assets/images/kk.png);
  background-repeat: no-repeat;
}

.pice_imgg {
  display: block;
  margin: 11px auto;
}

.pay_explain {
  position: relative;
  color: #666666;
  line-height: 50px;
}

.pay_explain p + p {
  text-indent: 2em;
  line-height: 20px;
}

.show-wxpay-scan {
  position: relative;
  padding: 15px;
  width: 220px;
  height: 280px;
  text-align: center;
  display: none;
}

.show-wxpay-scan {
  text-align: center;
}
</style>